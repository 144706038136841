<template>
    <div class="product-list">
      <!-- 标题与保存按钮 -->
      <el-row>
        <el-col :span="12"><Title title="在线数据占比"></Title></el-col>
        <el-col :span="12">
          <div style="width:100%;height:60px;padding-right:15px;box-sizing:border-box" class="flex_rec">
            <!-- <el-button type="primary" @click="addObj">添加音效</el-button> -->
          </div>
        </el-col>
      </el-row>
      <!-- 产品列表 -->
      <el-card class="box-card">
        <el-row :gutter="20" type="flex" align="middle" justify="start">
          <el-col :span="4">
            <el-input placeholder="请输入内容" v-model="search_op.limit" @change="getList" clearable>
              <template slot="prepend">采样数</template>
            </el-input>
          </el-col>
          <el-col :span="1.5">
            <el-button @click="getList">更新</el-button>
          </el-col>
          <el-col :span="3">
            返回采样数 {{total}}
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" align="middle" justify="start">
          <el-col :span="8">
            <Persent ref="Persent1" />
          </el-col>
          <el-col :span="8">
            <Persent ref="Persent2" />
          </el-col>
          <el-col :span="8">
            <Persent ref="Persent3" />
          </el-col>
        </el-row>
      </el-card>
    </div>
  </template>
  
  <script>
  import Title from "./components/Title"
  import apis from "@/api/index"
  import util from "@/lib/util.js"
  import Persent from './components/Persent.vue'
  export default {
      components:{
        Title,
        Persent
      },
      name:'DataPersent',
      data(){
        return {
          total:0,
          search_op:{
            limit:10000,
          },
          PER_DEFINED_COLORS:[
              '#ff783c',
              '#5a5a5a',
              '#a8a09c',
              '#5983ff',
              '#ff599e',
              '#ff8d42',
              '#5392ff',

              '#27c7c1',
              '#ffd340',
              '#bd98f7',
              '#b2effc',
              '#ffd5d9',

              '#1ddbb2',
              '#242e2d',
              '#3c3b3d',
          ]
        }
      },
      methods:{
              confirm(ob,callback){
                  this.$confirm(ob.msg, '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: !ob.type?'warning':ob.type
                  }).then(() => {
                      callback()
                  }).catch((e) => {
                      console.error(e)
                      this.$message({
                          type: 'info',
                          message: '已取消'
                      });          
                  });
              },
        getList(toS=true){
          if(toS){
            this.page_num = 1;
          }
          let op = {
            page_num:this.page_num,
            page_size:this.page_size
          }
          Object.assign(op,this.search_op)
          apis.onlineUser.getOnlineDataPersent(op).then(res=>{
            console.log('res',res)
            this.setArchData(res.data.data.list)
            this.setPlatformData(res.data.data.list)
            this.setSoftwareVersionData(res.data.data.list)

            this.total = res.data.data.list.total
          }).catch(err=>{
            console.log('err',err)
          })
        },
        setArchData(list){
          let labels =  Object.keys(list.arch)
          let chartData ={
            labels: labels,
            datasets: [
              {
                backgroundColor: this.PER_DEFINED_COLORS.slice(0,labels.length),
                data:  Object.values(list.arch)
              }
            ]
          }
          this.$refs.Persent1.setData(chartData)
        },
        setPlatformData(list){
          let labels =  Object.keys(list.platform)
          let chartData ={
            labels: labels,
            datasets: [
              {
                backgroundColor: this.PER_DEFINED_COLORS.slice(0,labels.length),
                data:  Object.values(list.platform)
              }
            ]
          }
          this.$refs.Persent2.setData(chartData)
        },
        setSoftwareVersionData(list){
          let labels =  Object.keys(list.softwareVersion)
          let chartData ={
            labels: labels,
            datasets: [
              {
                backgroundColor: this.PER_DEFINED_COLORS.slice(0,labels.length),
                data:  Object.values(list.softwareVersion)
              }
            ]
          }
          this.$refs.Persent3.setData(chartData)
        },
        handleSizeChange(val) {
          this.page_size = val
          this.getList(false)
          console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
          this.page_num = val
          this.getList(false)
          console.log(`当前页: ${val}`);
        },
      },
      activated(){
        this.getList(false)
      },
      mounted(){
        this.getList()
  
      }
  }
  </script>
  
  <style lang="scss" scoped>
  .product-list{
    padding:15px;
  }
  
  </style>